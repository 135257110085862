
import Vue from "vue";
import { Component } from "vue-property-decorator";
import billingAccountApi from "@/api/billingAccountApi";
import DynamicTitle from "@/components/DynamicTitle";
import SideFilter from "@/components/SideFilter";
import { AccountInfo } from "@/models/billingAccount/AccountInfo";
import { billingAccountModule } from "@/store/modules/billingAccount";
import { BillingAccountActions } from "@/store/modules/billingAccount/actions";
import { BillingAccountGetters } from "@/store/modules/billingAccount/getters";
import { BillingAccountMutation } from "@/store/modules/billingAccount/mutations";
import { makeRequest } from "@/services/requestUtils";
import { getYearMonthString } from "@/services/dateFormatUtils";
import billingDates from "@/consts/billingDates";

@Component({
    components: { DynamicTitle, SideFilter },
    methods: {
        ...billingAccountModule.mapActions({
            loadAccountInfo: BillingAccountActions.LoadAccountInfo
        }),
        ...billingAccountModule.mapMutations({
            setYearAndMonth: BillingAccountMutation.SetYearAndMonth
        })
    },
    computed: billingAccountModule.mapGetters({
        accountInfo: BillingAccountGetters.AccountInfo,
        isLoading: BillingAccountGetters.IsLoading,
        year: BillingAccountGetters.Year,
        month: BillingAccountGetters.Month,
        errors: BillingAccountGetters.Errors
    })
})
export default class BillingAccount extends Vue {
    readonly accountInfo!: AccountInfo;
    readonly isLoading!: boolean;
    readonly year!: number;
    readonly month!: number;
    readonly errors!: string[];

    readonly headers = [
        { text: "Type", value: "billingType" },
        { text: "Number of not calculated cases", value: "count", width: 250 }
    ];
    readonly billingDates = billingDates;

    readonly loadAccountInfo!: () => Promise<void>;
    readonly setYearAndMonth!: (value: { year: number; month: number }) => void;
    readonly getYearMonthString = getYearMonthString;

    isBilling = false;

    get pickedDate(): string {
        return this.getYearMonthString(this.year, this.month - 1);
    }

    set pickedDate(dateString: string) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        this.setYearAndMonth({ year, month });
        this.loadAccountInfo();
    }

    created(): void {
        this.loadAccountInfo();
    }

    async bill(): Promise<void> {
        await makeRequest(
            () => billingAccountApi.createBillings(this.year, this.month),
            (val) => (this.isBilling = val)
        );
        await this.loadAccountInfo();
    }
}
