
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import DynamicTitle from "@/components/DynamicTitle";
import SideFilter from "@/components/SideFilter";
import { billingModule } from "@/store/modules/billing";
import { BillingActions } from "@/store/modules/billing/actions";
import { BillingGetters } from "@/store/modules/billing/getters";
import { notificationsModule } from "@/store/modules/notifications";
import { NotificationsActions } from "@/store/modules/notifications/actions";
import { BillingMutation } from "@/store/modules/billing/mutations";
import {
    getYearMonthString,
    getFullMonthYearString
} from "@/services/dateFormatUtils";
import billingDates from "@/consts/billingDates";
import { MonthBillingData } from "@/models/billing/MonthBillingData";
import { BillingData } from "@/models/billing/BillingData";
import billingReportGenerator from "@/services/billingReportGenerator";
import { BillingTableItem } from "@/models/billing/billingTableItem";

@Component({
    components: { DynamicTitle, SideFilter },
    methods: {
        ...billingModule.mapActions({
            loadMonthBillingData: BillingActions.LoadMonthBillingData
        }),
        ...billingModule.mapMutations({
            setYearAndMonth: BillingMutation.SetYearAndMonth
        }),
        ...notificationsModule.mapActions({
            showErrors: NotificationsActions.NotifyError
        })
    },
    computed: billingModule.mapGetters({
        monthBillingData: BillingGetters.MonthBillingData,
        isLoading: BillingGetters.IsLoading,
        year: BillingGetters.Year,
        month: BillingGetters.Month,
        errors: BillingGetters.Errors
    })
})
export default class BillingAccount extends Vue {
    readonly monthBillingData!: MonthBillingData[];
    readonly isLoading!: boolean;
    readonly year!: number;
    readonly month!: number;
    readonly errors!: string[];

    readonly headers = [
        { text: "Type", value: "billingType", width: 360 },
        { text: "Number of cases", value: "casesCount", width: 140 },
        { text: "Cases", value: "cases" }
    ];
    readonly billingDates = billingDates;

    readonly loadMonthBillingData!: () => Promise<void>;
    readonly setYearAndMonth!: (value: { year: number; month: number }) => void;
    readonly showErrors!: (errors: string[] | string) => void;
    readonly getYearMonthString = getYearMonthString;

    get pickedDate(): string {
        return this.getYearMonthString(this.year, this.month - 1);
    }

    set pickedDate(dateString: string) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        this.setYearAndMonth({ year, month });
        this.loadMonthBillingData();
    }

    getMonthBillingTableItems(billingData: BillingData[]): BillingTableItem[] {
        return billingData.map((bd) => ({
            billingType: bd.billingType,
            casesCount: bd.caseInternalIds.length,
            cases: bd.caseInternalIds.join(", ")
        }));
    }

    created(): void {
        this.loadMonthBillingData();
    }

    @Watch("errors")
    onErrors(errors: string[]): void {
        this.showErrors(errors);
    }

    downloadReport(): void {
        const doc = billingReportGenerator.generatePdfBillingReport(
            this.monthBillingData,
            this.year,
            this.month - 1
        );
        const monthYearString = getFullMonthYearString(
            this.year,
            this.month - 1
        );
        doc.save(`IFM Billing Report ${monthYearString}`);
    }
}
