import jsPDF from "jspdf";
import { applyPlugin, Table, UserOptions } from "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { MonthBillingData } from "../models/billing/MonthBillingData";
import { a4Options } from "./pdfGenerator";
import { getFullMonthYearString, getYearMonthString } from "./dateFormatUtils";
import { groupBy, keys, sum } from "lodash";

const defaultTableStyle = {
    theme: "plain" as UserOptions["theme"],
    styles: {
        lineColor: [0, 0, 0] as [number, number, number],
        lineWidth: 1
    },
    showHead: "everyPage" as UserOptions["showHead"]
};

applyPlugin(jsPDF);

function generatePdfBillingReport(
    monthBillingData: MonthBillingData[],
    year: number,
    month: number
): jsPDF {
    const doc = new jsPDF({ ...a4Options }) as jsPDF & {
        lastAutoTable?: Table;
    };
    const marginX = 40;
    const fullMonthYearString = getFullMonthYearString(year, month);

    let cursorY = 60;
    doc.setFont("times", "normal", "bold");
    doc.setFontSize(18);
    doc.text(`Billing Cases ${fullMonthYearString}`, marginX, cursorY);
    cursorY += 25;

    doc.setFont("times", "normal", "normal");
    doc.setFontSize(12);
    const billingText = "Billing Month: ";
    const billingTextWidth = doc.getTextWidth(billingText);
    doc.text(billingText, marginX, cursorY);
    doc.setFont("times", "normal", "bold");
    doc.text(fullMonthYearString, marginX + billingTextWidth, cursorY);
    cursorY += 35;

    for (const monthBilling of monthBillingData) {
        const yearMonthString = getYearMonthString(
            monthBilling.year,
            monthBilling.month - 1
        );
        doc.text(`Creation Month: ${yearMonthString}`, marginX, cursorY);
        cursorY += 2;

        const tableBillingData = monthBilling.billingData
            .filter((bd) => bd.caseInternalIds.length > 0)
            .map((bd) => ({
                type: bd.billingType,
                number: bd.caseInternalIds.length.toString(),
                cases: bd.caseInternalIds.join(", ")
            }));
        autoTable(doc, {
            ...defaultTableStyle,
            startY: cursorY,
            head: [{ type: "Type", number: "Number", cases: "Cases" }],
            body: tableBillingData,
            columnStyles: {
                type: { cellWidth: 120 },
                number: { cellWidth: 90 },
                cases: { cellWidth: "auto" }
            }
        });
        if (doc.lastAutoTable?.finalY) cursorY = doc.lastAutoTable.finalY + 20;
    }

    const groupedBillingData = groupBy(
        monthBillingData.flatMap((mbd) => mbd.billingData),
        (bd) => bd.billingType
    );

    const summaryData = keys(groupedBillingData).map((k) => ({
        type: k,
        number: groupedBillingData[k].flatMap((b) => b.caseInternalIds).length
    }));
    summaryData.push({
        type: "Total",
        number: sum(summaryData.flatMap((sd) => sd.number))
    });
    autoTable(doc, {
        ...defaultTableStyle,
        startY: cursorY,
        head: [{ type: "Type (IFM)", number: "Number of Cases" }],
        body: summaryData,
        tableWidth: 300
    });

    return doc;
}

export default {
    generatePdfBillingReport
};
