import { getYearMonthString } from "@/services/dateFormatUtils";

const minMonth = "2020-01";

const date = new Date();
const maxMonth = getYearMonthString(date.getFullYear(), date.getMonth());

export default {
    minMonth,
    maxMonth
};
